@import '../abstracts/theme.less';

html {
  position: relative;
  height: 100%;
  box-sizing: border-box;
  min-height: none;
}

body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  background-color: var(--background);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

/* --------------- header --------------- */

#header .logo {
  margin-left: 1.2rem;
  display: inline-flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
}

#jenkins-home-link {
  position: relative;
}

#jenkins-head-icon {
  height: 2.5rem;
}

#jenkins-name-icon {
  margin-left: 0.25rem;
}

#breadcrumbBar,
#footer-container,
.top-sticker-inner {
  background-color: var(--breadcrumbs-bg);
}

.top-sticker-inner {
  border-bottom: 1px solid --var(breadcrumbs-border);
}

/* -------------------------------------- */

#page-body {
  display: flex;
  align-items: stretch;
  flex: 1 0 auto;
}

#page-body.clear::after {
  clear: both;
  content: "";
  display: table;
}

#side-panel {
  padding: 1rem 0 2.5rem 0;
  width: 300px;
  flex-shrink: 0;
  border-right: 1px solid var(--panel-border-color);
}

#main-panel {
  padding: 2rem;
  display: inline-block;
  width: 100%;
}

body.two-column #main-panel {
  width: calc(100% - 320px);
  flex: 1;
  display: block;
}

body.full-screen {
  padding: 0;
}

body.full-screen #main-panel {
  padding: 0;
}

@media (max-width: 970px) {
  body.two-column #page-body {
    flex-wrap: wrap;
  }

  body.two-column #side-panel {
    width: 100%;
    padding-bottom: 20px;
    border-right: none;
  }

  body.two-column #main-panel {
    margin-left: 0;
    width: 100%;
  }
}

@media (min-width: 1170px) {
  body.two-column #side-panel {
    width: 340px;
  }

  body.two-column #main-panel {
    width: calc(100% - 370px);
  }
}

/* -------------------------------------- */

h1.build-caption.page-headline {
    display: flex;
    align-items: center;
    max-width: 1200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// Clearfixes extracted from responsive-grid.css as they essential
.clearfix::before,
.clearfix::after,
.container::before,
.container::after,
.container-fluid::before,
.container-fluid::after,
.row::before,
.row::after,
.form-horizontal .form-group::before,
.form-horizontal .form-group::after,
.btn-toolbar::before,
.btn-toolbar::after,
.btn-group-vertical > .btn-group::before,
.btn-group-vertical > .btn-group::after,
.nav::before,
.nav::after,
.navbar::before,
.navbar::after,
.navbar-header::before,
.navbar-header::after,
.navbar-collapse::before,
.navbar-collapse::after,
.pager::before,
.pager::after,
.panel-body::before,
.panel-body::after,
.modal-footer::before,
.modal-footer::after {
  display: table;
  content: " ";
}

.clearfix::after,
.container::after,
.container-fluid::after,
.row::after,
.form-horizontal .form-group::after,
.btn-toolbar::after,
.btn-group-vertical > .btn-group::after,
.nav::after,
.navbar::after,
.navbar-header::after,
.navbar-collapse::after,
.pager::after,
.panel-body::after,
.modal-footer::after {
  clear: both;
}
